import React from "react";

export default function LeafGuard() {
  return (
    <section className="leafGuard__section">
      <div className="leafGuard__div-background"></div>
      <div className="leafGuard__div-backgroundColor"></div>
      <div className="leafGuard__div-heading">
      <h2 className="leafGuard__h2">Leaf Guard</h2>
      <h3 className="leafGuard__h3">Keep your gutter free from leaves</h3>
      </div>
      <div className="leafGuard__div-cont">
          <div className="leafGuard__div-gradeCont">
              <div className="leafGuard__div-img1"></div>
              <div className="leafGuard__div-grade">
                  <p className="leafGuard__p-grade">Standard</p>
              </div>
              <div className="leafGuard__div-text">
                  <p className="leafGuard__p-title">K Screen</p>
                  <p className="leafGuard__p-subTitle">6 Year Coating Guarantee</p>
                  <p className="leafGuard__p-body">K-Screen is a versatile gutter screen that fits all gutter styles. Its powder-coated, expanded metal snaps into the gutter for a seamless look with no screws or fasteners needed. With a baked-on coating it will resist corrosion and keep your gutters free from leaves.</p>
                  <a href="http://k-screen.us/"><p className="leafGuard__p-btn cta__btn-blue">Learn More</p></a>
              </div>
          </div>

          <div className="leafGuard__div-gradeCont">
              <div className="leafGuard__div-img2"></div>
              <div className="leafGuard__div-grade">
                  <p className="leafGuard__p-grade">High Grade</p>
              </div>
              <div className="leafGuard__div-text">
                  <p className="leafGuard__p-title">Bulldog</p>
                  <p className="leafGuard__p-subTitle">25 Year Clog-Free Guarantee</p>
                  <p className="leafGuard__p-body">K-Screen is a versatile gutter screen that fits all gutter styles. Its powder-coated, expanded metal snaps into the gutter for a seamless look with no screws or fasteners needed. With a baked-on coating it will resist corrosion and keep your gutters free from leaves.</p>
                  <a href="http://bulldoggutterguard.com"><p className="leafGuard__p-btn cta__btn-blue">Learn More</p></a>
              </div>
          </div>

          <div className="leafGuard__div-gradeCont">
              <div className="leafGuard__div-img3"></div>
              <div className="leafGuard__div-grade">
                  <p className="leafGuard__p-grade">Premium</p>
              </div>
              <div className="leafGuard__div-text">
                  <p className="leafGuard__p-title">Leaf Sentry</p>
                  <p className="leafGuard__p-subTitle">Lifetime Clog Free Guarantee</p>
                  <p className="leafGuard__p-body">K-Screen is a versatile gutter screen that fits all gutter styles. Its powder-coated, expanded metal snaps into the gutter for a seamless look with no screws or fasteners needed. With a baked-on coating it will resist corrosion and keep your gutters free from leaves.</p>
                  <a href="http://leafsentry.com"><p className="leafGuard__p-btn cta__btn-blue">Learn More</p></a>
              </div>
          </div>
      
      </div>
      <p className="leafGuard__p">See More Products from <a href="https://www.leafguard.com/">Leaf Guard</a> and <a href="https://www.raytecllc.com/">Raytec</a></p>
    </section>
  );
}

import React from "react";

export default function Materials() {


  return (
    <section className="materials__section-container">
        <div className="materials__div-colorBox"></div>
        <div className="materials__div-Text">
            <h2 className="materials__h2">Quality Materials</h2>
            <h3 className="materials__h3">Which will you choose?</h3>
            <p className="materials__p">Choosing the right material for your job is very important. With options in luxurious Copper, Aluminum and durable Steel, we can meet the demands of your job. We have been in the business for years and are happy to consult with you on which material will best suit your next project. Are you looking for durability? Style? Or maybe both? Whatever the need, we have the right materials for the job.</p>
        </div>
        <div className="materials__div-img"></div>
    </section>
  );
}

import React from "react";

export default function FullwidthCTA() {
  return (
    <section className="fullWidth__section-container">
      <div className="fullWidth__div-container">
        <div className="fullWidth__div-leftAligned">
          <p className="fullWidth__p-bigText">1000+ Homes. 100+ Commercial.</p>
          <p className="fullWidth__p-bigggerText">
            Best in the Business since 1996.
          </p>
        </div>
        <div className="fullWidth__div-rightAligned">
          <a href="/contact">
            <p className="fullWidth__btn cta__btn-green">
              Book a Free Consultation Now
            </p>
          </a>
        </div>
      </div>
    </section>
  );
}

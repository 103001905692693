import React from 'react';
import { Link } from 'react-router-dom';

export default function HomeBuy() {
    return(
        <section className="homebuy__cont">
            <div className="homebuy__text-cont">
                <p className="homebuy__tagline">Buy Local. Buy Direct. Made In USA.</p>
            </div>
            <div className="homebuy__action-cont">
                <Link to="/contact" className="cta__btn-green">Book a Free Consultation Now</Link>
            </div>
        </section>
    )
}
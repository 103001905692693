import React from "react";

export default function Claims() {
  return (
    <section className="claims__section-container">
      <div className="claims__div-colorBox"></div>
      <div className="claims__div-textCont">
        <h2 className="claims__h2">Insurance Claims</h2>
        <p className="claims__p">
          If you are experiencing property damage, it is most likely the first
          time that something of this magnitude has ever happened to you. Morgan
          Exteriors understands that there are a number of questions that come
          to mind as the initial shock subsides. Working with major insurance
          providers, we can help you navigate the entire claims process quickly
          and efficiently.
        </p>
        <a href="/contact"><p className="claims__p-btn cta__btn-green">
          Request an Appointment Today
        </p></a>
      </div>
      <div className="claims__div-img"></div>
    </section>
  );
}

import React from 'react';


function NotFound() {
    return(
        <main className="not-found">
            <section className="format-text">
                <h1>404</h1>
                <h2>Page Not Found.</h2>
                <p>Like all our restoration projects, we are constantly looking to improve our website. We apologize that this link is broken. Look below for more options.</p>
            </section>
        </main>
    );
}
export default NotFound;
import React, {useEffect} from 'react';
import { Helmet } from "react-helmet";
import { usePageView } from "../../../services/customHooks";
import { Link } from 'react-router-dom';


export default function SiteMap(props) {
    usePageView(props.location.pathname);
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
    return(
        <main className="site-map">
             <Helmet>
                <title>Sitemap | Morgan Exteriors</title>
                <meta name="description" content="Sitemap to the core webpages on the Morgan Exteriors website."/>
            </Helmet>
            <section className="sitemap__section">
                <div className="sitemap__div">
                <h1 className="sitemap__h1">Morgan Exteriors Sitemap</h1>
                 <div className="sitemap__div-container">

                <Link className="sitemap__link--parent" to="/">Home</Link>
                <Link className="sitemap__link--child" to="/siding">Siding</Link>
                <Link className="sitemap__link--child" to="/gutter">Gutter</Link>
                <Link className="sitemap__link--child" to="/our-work">Our Work</Link>
                <Link className="sitemap__link--child" to="/insurance">Insurance</Link>
                <Link className="sitemap__link--child" to="/contact">Contact</Link>

                <Link className="sitemap__link--child" to="/terms-of-use">Terms Of Use</Link>
                <Link className="sitemap__link--child" to="/privacy-policy">Privacy Policy</Link>
                <Link className="sitemap__link--child" to="/sitemap">Sitemap</Link>
                
            </div>
            </div>
            </section>
        </main>
    );
}
import React from "react";


export default function InsuranceBanner() {
  return (
    <section className="insuranceBanner__section-container">
      <div className="insuranceBanner__div-container">
        <h1 className="insuranceBanner__h1">We Have Your Back</h1>
        </div>
    </section>
  );
}
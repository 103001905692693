import React, { useState } from "react";



export default function TruCedar() {
  return (
    <section className="truCedar__section" id="trucedar-shakes">
      <h2 className="truCedar__h2">TruCedar Shakes Sidewall</h2>

      <div className="truCedar__div-colorBlock">
        <div className="truCedar__div-Cont">
        <div className="truCedar__img"></div>
        <div className="truCedar__div-textCont">
          <p className="truCedar__p">
          Shake Sidewall gives you that classic shaker look, without the constant maintenance or poor longevity. Our Shake Sidewall resists the elements of rain, hail, and snow and is expected to last considerably longer than standard shakes. With a maintenance free lifetime warranty and fade resistant technology, this siding is sure to be a fan favorite.</p>

          <p>
          This style is offered in a variety of materials. Call us today to see if we have your style and material available.  
          </p>
          <a href="https://www.qualityedge.com/siding/"><p className="truCedar__btn cta__btn-blue">Learn More</p></a>
        </div>
        </div>
      </div>
    </section>
  );
}

import React, {useEffect} from 'react';
import { Helmet } from "react-helmet";
import { usePageView } from "../../../services/customHooks";


export default function TermsOfUse(props) {
    usePageView(props.location.pathname);
    useEffect(() => {
      window.scrollTo(0, 0)
    }, []);
    return(
        <main className="terms-of-use">    
      <section className="termsOfUse__section">
         <div className="termsOfUse__div">
          <h1 className="termsOfUse__h1">Terms Of Use</h1>
          <h2 className="termsOfUse__h2">1. Terms</h2>
          <p className="termsOfUse__p">
            By accessing this web site, you are agreeing to be bound by these
            web site Terms and Conditions of Use, all applicable laws and
            regulations, and agree that you are responsible for compliance with
            any applicable local laws. If you do not agree with any of these
            terms, you are prohibited from using or accessing this site. The
            materials contained in this web site are protected by applicable
            copyright and trade mark law.
          </p>
          <h2 className="termsOfUse__h2">2. Use License</h2>
          <p className="termsOfUse__p">
            Permission is granted to temporarily download one copy of the
            materials (information or software) on Morgan Exteriors’s
            web site for personal, non-commercial transitory viewing only. This
            is the grant of a license, not a transfer of title, and under this
            license you may not:
          </p>
          <ol type="a" className="termsOfUse__ol">
            <li className="termsOfUse__li">modify or copy the materials;</li>
            <li className="termsOfUse__li">
              use the materials for any commercial purpose, or for any public
              display (commercial or non-commercial);
            </li>
            <li className="termsOfUse__li">
              attempt to decompile or reverse engineer any software contained on
              Morgan Exteriors’s web site;
            </li>
            <li className="termsOfUse__li">
              remove any copyright or other proprietary notations from the
              materials; or
            </li>
            <li className="termsOfUse__li">
              transfer the materials to another person or “mirror” the materials
              on any other server.
            </li>
          </ol>
          <p className="termsOfUse__p">
            This license shall automatically terminate if you violate any of
            these restrictions and may be terminated by Morgan Exteriors at any time. Upon terminating your viewing of these
            materials or upon the termination of this license, you must destroy
            any downloaded materials in your possession whether in electronic or
            printed format.
          </p>
          <h2 className="termsOfUse__h2">3. Disclaimer</h2>
          <p className="termsOfUse__p">
            The materials on Morgan Exteriors’ web site are provided
            “as is”. Morgan Exteriors makes no warranties, expressed
            or implied, and hereby disclaims and negates all other warranties,
            including without limitation, implied warranties or conditions of
            merchantability, fitness for a particular purpose, or
            non-infringement of intellectual property or other violation of
            rights. Further, Morgan Exteriors does not warrant or make any
            representations concerning the accuracy, likely results, or
            reliability of the use of the materials on its Internet web site or
            otherwise relating to such materials or on any sites linked to this
            site.
          </p>
          <h2 className="termsOfUse__h2">4. Limitations</h2>
          <p className="termsOfUse__p">
            In no event shall Morgan Exteriors or its suppliers be
            liable for any damages (including, without limitation, damages for
            loss of data or profit, or due to business interruption,) arising
            out of the use or inability to use the materials on Morgan Exteriors’ Internet site, even if Morgan Exteriors or a Morgan Exteriors authorized representative has been notified orally
            or in writing of the possibility of such damage. Because some
            jurisdictions do not allow limitations on implied warranties, or
            limitations of liability for consequential or incidental damages,
            these limitations may not apply to you.
          </p>
          <h2 className="termsOfUse__h2">5. Revisions and Errata</h2>
          <p className="termsOfUse__p">
            The materials appearing on Morgan Exteriors’s web site
            could include technical, typographical, or photographic errors.
            Morgan Exteriors does not warrant that any of the materials on
            its web site are accurate, complete, or current. Morgan Exteriors may make changes to the materials contained on its web
            site at any time without notice. Morgan Exteriors does not,
            however, make any commitment to update the materials.
          </p>
          <h2 className="termsOfUse__h2">6. Links</h2>
          <p className="termsOfUse__p">
            Morgan Exteriors has not reviewed all of the sites linked
            to its Internet web site and is not responsible for the contents of
            any such linked site. The inclusion of any link does not imply
            endorsement by Morgan Exteriors of the site. Use of any such
            linked web site is at the user’s own risk.
          </p>
          <h2 className="termsOfUse__h2">7. Site Terms of Use Modifications</h2>
          <p className="termsOfUse__p">
            Morgan Exteriors may revise these terms of use for its web
            site at any time without notice. By using this web site you are
            agreeing to be bound by the then current version of these Terms and
            Conditions of Use.
          </p>
          <h2 className="termsOfUse__h2">8. Governing Law</h2>
          <p className="termsOfUse__p">
            Any claim relating to Morgan Exteriors’s web site shall be
            governed by the laws of the State of Idaho without regard to its
            conflict of law provisions.
          </p>
        </div> 
      </section> 
        </main>
    );
}
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { usePageView } from "../../../services/customHooks";
import ImageGallery from "react-image-gallery";
import photo1 from "../../../images/work/ourWork1.jpg";
import photo2 from "../../../images/work/ourWork2.jpg";
import photo3 from "../../../images/work/ourWork3.jpg";
import photo4 from "../../../images/work/ourWork4.jpg";
import photo5 from "../../../images/work/ourWork5.jpg";
import photo6 from "../../../images/work/ourWork6.jpg";
import photo7 from "../../../images/work/ourWork7.jpg";
import photo8 from "../../../images/work/ourWork8.jpg";
import photo9 from "../../../images/work/ourWork9.jpg";
import photo10 from "../../../images/work/ourWork10.jpg";
import photo11 from "../../../images/work/ourWork11.jpg";
import photo12 from "../../../images/work/ourWork12.jpg";

export default function OurWork(props) {
  usePageView(props.location.pathname);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const images = [
    { original: photo1, originalClass: "our-work__gallery-image" },
    { original: photo2, originalClass: "our-work__gallery-image" },
    { original: photo3, originalClass: "our-work__gallery-image" },
    { original: photo4, originalClass: "our-work__gallery-image" },
    { original: photo5, originalClass: "our-work__gallery-image" },
    { original: photo6, originalClass: "our-work__gallery-image" },
    { original: photo7, originalClass: "our-work__gallery-image" },
    { original: photo8, originalClass: "our-work__gallery-image" },
    { original: photo9, originalClass: "our-work__gallery-image" },
    { original: photo11, originalClass: "our-work__gallery-image" },
    { original: photo12, originalClass: "our-work__gallery-image" },
  ];

  return (
    <main className="our-work__cont">
      <Helmet>
        <title>Morgan Exteriors - Gallery</title>
        <meta
          name="description"
          content="Morgan Exteriors Gallery Photos. Beautiful exteriors done by professionals. We are so proud of the work we do, we thought we would show it off a little..."
        />
      </Helmet>
      <h1 className="our-work__h1">Check out our Gallery</h1>
      <p className="our-work__p">
        We are so proud of the work we do, we thought we would show it off a
        little...
      </p>
      <div className="our-work__image-cont">
        <ImageGallery items={images} showThumbnails={false} />
      </div>
    </main>
  );
}

import React from "react";
import { Link } from 'react-router-dom';

export default function CloggedCTA() {
  return (
    <section className="cloggedCTA__section-container">
      <div className="cloggedCTA__div-colorBox"></div>
      
      <div className="cloggedCTA__div-textCont">
          <h2 className="cloggedCTA__h2">Clogged Gutters? We Can Help!</h2>
          
          <Link to="/contact"> <p className="cloggedCTA__p-btn cta__btn-green">Call to Schedule a Free Inspection</p> </Link>
          
      </div>
      <div className="cloggedCTA__div-img"></div>
    </section>
  );
}

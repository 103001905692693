import React, {useEffect} from 'react';
import { Helmet } from "react-helmet";
import { usePageView } from "../../../services/customHooks";
import HomeBanner from "./children/HomeBanner";
import SidingProducts from "./children/SidingProducts";
import Steel from "./children/Steel";
import LpSmartside from "./children/LpSmartside";
import FullwidthCTA from "./children/FullwidthCTA";
import VestaPlank from "./children/VestaPlank";
import TruCedar from "./children/TruCedar";
import SoffitFascia from "./children/SoffitFascia";
import Experts from "./children/Experts";
import Colors from "./children/Colors";


export default function Siding(props) {
    usePageView(props.location.pathname);

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    return(
        
        <main className="siding">
            
            <HomeBanner/>
            <SidingProducts/>
            <div className="spacing"></div>
            <Steel/>
            <div className="spacing"></div>
            <LpSmartside/>
            <div className="spacing"></div>
            <FullwidthCTA/>
            <div className="spacing"></div>
            <VestaPlank/>
            <div className="spacing"></div>
            <TruCedar/>
            <div className="spacing"></div>
            <Colors/>
            <div className="spacing"></div>
            <SoffitFascia/>
            <div className="spacing"></div>
            <Experts/>
            <div className="spacing"></div>
        </main>
    );
}
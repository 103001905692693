import React from 'react';
import logo from '../../../images/logo.png'
import { Link } from 'react-router-dom';

export default function Footer() {
    return(
        <footer className="footer">
            <div className="footer__cont1">
                <div className="footer__logo-cont">
                    <img src={logo} alt="Morgan Exteriors Logo" className="footer__logo"/>
                </div>
                <div className="footer__address-cont">
                    <p className="footer__p-address1">PO Box 50246</p>
                    <p className="footer__p-address2">Idaho Falls, Idaho 83405</p>
                    <p className="footer__p-address3">(208) 643-3612</p>
                </div>
                <div className="footer__link1-cont">
                    <p className="footer__p-footer-link-title">Legal</p>
                    <Link to="/privacy-policy" className="footer__link">Privacy Policy</Link>
                    <Link to="/site-map" className="footer__link">Site Map</Link>
                </div>
                <div className="footer__link2-cont">
                    <p className="footer__p-footer-link-title">Services</p>
                    <Link to="/siding" className="footer__link">Siding</Link>
                    <Link to="/gutter" className="footer__link">Gutter</Link>
                </div>
                <div className="footer__cta-cont">
                    <Link to="/contact" className="cta__btn-green footer__btn">Request and Appointment Today</Link>
                </div>
            </div>
            <div className="footer__cont2">
                <p className="footer__p-rights">All Rights Reserved. Copyright &copy; 2020 Morgan Exteriors</p>
            </div>
        </footer>
    );
}
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { usePageView } from '../../../services/customHooks';
import InsuranceBanner from './children/InsuranceBanner';
import InsuranceHighlights from './children/InsuranceHighlights';
import Claims from './children/Claims';
import InsuranceCTA from './children/InsuranceCTA';
import Excellence from './children/Excellence';
import VinylRepair from './children/VinylRepair';
import InsuranceRepair from './children/InsuranceRepair';

export default function Insurance(props) {
  usePageView(props.location.pathname);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="insurance">
      <InsuranceBanner />
      <InsuranceHighlights />
      <Claims />
      <div className="spacing"></div>
      <InsuranceRepair />
      <div className="spacing"></div>
      <InsuranceCTA />
      <div className="spacing"></div>
      <Excellence />
      <div className="spacing"></div>
      <VinylRepair />
      <div className="spacing"></div>
    </main>
  );
}

import React from "react";

export default function ContactUs() {
  return (
    <section className="contactUs__cont">
      <div className="contactUs__cont-center">
        <h2>Contact Us</h2>
        <div className="contactUs__div-grid">
          <div className="contactUs__div-boxOne">
            <h3>Shcedule a Free Inspection</h3>
        <p>Leave us a message and our team wil get back to you.</p>
        <p className="formMap__required required">* Required</p>

        {/* <!-- START CODING YOUR FORM --> */}
        <form action="https://docs.google.com/forms/d/e/1FAIpQLSc-EuVehbPt51VGBJfhwyEP_G5MzaTVzWQC540Oj-_zjIweHg/viewform?embedded=true" target="_blank">
          {/* <!-- SINGLE LINE TEXT FIELD --> */}
          <label htmlFor="name">
            First and Last Name<span className="required"> *</span>
          </label><br/>
          <input name="entry.715065547" type="text" id="name" /><br/>


          {/* <!-- SINGLE LINE TEXT FIELD --> */}
          <label htmlFor="phone">
            Phone Number<span className="required"> *</span>
          </label><br/>
          <input name="entry.210969997" type="tel" id="phone" /><br/>

          {/* <!-- SINGLE LINE TEXT FIELD --> */}
          <label htmlFor="email">Email Address</label><br/>
          <input name="entry.1551957953" type="email" id="email" /><br/>

          
          {/* <!-- MULTI-LINE TEXT FIELD --> */}
          <label htmlFor="explain">Write Message</label>
          <textarea name="entry.1476439802" id="explain" />

          
          <br/>

          {/* <!-- SUBMIT BUTTON --> */}
          <input className="button cta__btn-green" type="submit" value="Submit" target="_blank" rel="noopener noreferrer"/>
        </form>
          </div>
          <div className="contactUs__div-boxTwo">
            <div className="contactUs__div-spacing">
              <h3 className="contactUs__h3">Or Just Give Us a Call</h3>
              <p className="contactUs__p">The easiest way to get a hold of us is to just to pick up the phone and give us a ring. Our professionals are ready to take your call and answer your questions.</p>
              <p className="cta__btn-green contactUs__p-bt"><a href="tel:2086433612">(208) 643-3612</a></p>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

import React from "react";

export default function Excellence() {
  return (
    <section className="excellence__section-container">
        <div className="excellence__div-imgCont"></div>
            <div div className="excellence__div-Text">
          <h2 className="excellence__h2">Committed To Excellence</h2>
          <p className="excellence__p">
          We have over 20 years of experience with Vinyl Fencing, Roofing, Siding, & Windows. We Proudly service all of southeast Idaho and western Wyoming. All of our technicians are highly trained and use all of the latest tools and equipment available to ensure a job well done. </p>

            <p className="excellence__p">We also have vast experience working with insurance agents and companies which will allow us to help walk you through the complex insurance process.</p>

            <p className="excellence__p">Give us a call today and get a free inspection. No deductible required.</p>
        </div>
        
          
    </section>
  );
}

import React, { useState } from "react";


export default function SoffitFascia() {

    return(
        <section className="soffitFascia__section" id="soffit-and-fascia">
            <h2 className="soffitFascia__h2">Soffit and Fascia</h2>
            
            <div className="soffitFascia__div-colorBlock">

            <div className="soffitFascia__div-Cont">

                <div className="soffitFascia__div-textCont">
                    <p className="soffitFascia__p">
                    With crisp, clean lines and impeccable style, our aluminum soffit and fascia will lend the perfect touch of refinement to your home while protecting the rooflines, gables and other hard-to-reach areas with a durable finish that's easy to maintain.  Choose from a variety of profiles featuring a low-luster LoMar polyester finish or semi-gloss PVC coating to create the ideal system for your home.</p>

                    <p>You may not realize it, but soffit and fascia play an important role in the structural integrity of your home. Properly installed soffit provides essential ventilation and air circulation to reduce excess moisture that can cause rotting and decay of the roof's structure. It promotes directional air movement through the eaves and rafters of your home, helping it to stay in great shape both inside and out.</p>
                    <a href="http://www.emcobuildingproducts.com/Soffit-Fascia-Rain-Carrying-Systems"><p className="soffitFascia__btn cta__btn-blue">Learn More</p></a>
                </div>
                <div className="soffitFascia__img"/></div>
                </div>
        </section>

    );

}

import React from 'react';
import { Link } from 'react-router-dom';
import seamlessSteel from '../../../../images/home/seamlessSteel.jpg';
import seamlessGutter from '../../../../images/home/seamlessGutter.jpg';
import lpSmartside from '../../../../images/home/lpSmartside.jpg';
import vestaPlank from '../../../../images/home/vestaPlank.jpg';

export default function HomeSpecialties() {
  const specialties = [
    {
      name: 'Seamless Steel Siding',
      desc:
        'Say goodbye to those irritating seams every 12’, our seamless steel siding is custom made on the job site in order to provide a perfect fit for your home or business. Several colors and embossing options are available for a beautiful presentation with exquisite texture.',
      cta: 'Learn More',
      link: '/siding',
      imgsrc: '/static/media/seamlessSteel.7439daf2.jpg',
    },
    {
      name: 'LP Smartside',
      desc:
        'LP Smartside composite siding gives you that warm wood look without compromising any longevity. With several painted colors to choose from, this siding may provide the perfect look for you.',
      cta: 'Learn More',
      link: '/siding',
      imgsrc: '/static/media/lpSmartside.46d6adb0.jpg',
    },
    {
      name: 'Vestaplank',
      desc:
        'Vestaplank is all the rage right now. It is famous for its longevity and wood-like imitation known as TruCedar. Available in many colors and wood grains, Vestaplank is a great choice for your next project.',
      cta: 'Learn More',
      link: '/siding',
      imgsrc: '/static/media/vestaPlank.06defa2b.jpg',
    },
    {
      name: 'Seamless Gutters',
      desc:
        'Our seamless gutters offer a very clean presentation that accent every home. They come in a variety of custom components, colors and materials. With our large product offering, you will find exactly what your home needs to really pop. Enjoy peace of mind knowing your gutters have the water management covered.',
      cta: 'Learn More',
      link: '/gutter',
      imgsrc: '/static/media/seamlessGutter.5d551b08.jpg',
    },
  ];

  return (
    <section className="home-specialties__cont">
      <h2 className="home-specialties__h2">Our Specialties</h2>
      {specialties.map((specialty, index) => {
        const style = {
          backgroundImage: `url(${specialty.imgsrc})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        };
        return (
          <div className="home-specialty__cont" style={style}>
            <div className="home-specialty__no-text-area"></div>
            <div className="home-specialty__text-area">
              <h3 className="home-specialty__h3">{specialty.name}</h3>
              <p className="home-specialty__p">{specialty.desc}</p>
              <Link
                to={specialty.link}
                className="cta__btn-green home-specialty__btn"
              >
                {specialty.cta}
              </Link>
            </div>
          </div>
        );
      })}
      <div className="home-specialties__parent-cont">
        <div className="home-specialties__child-cont1">
          <div className="home-specialty__no-text-area"></div>
          <div className="home-specialty__text-area">
            <h3 className="home-specialty__h3">Seamless Steel Siding</h3>
            <p className="home-specialty__p">
              Say goodbye to those irritating seams every 12’, our seamless
              steel siding is custom made on the job site in order to provide a
              perfect fit for your home or business. Several colors and
              embossing options are available for a beautiful presentation with
              exquisite texture.
            </p>
            <Link to="/siding" className="cta__btn-green home-specialty__btn">
              Learn More
            </Link>
          </div>
        </div>
        <div className="home-specialties__child-cont2">
          <div className="home-specialties__grandchild-cont1">
            <div className="home-specialties__great-grandchild1-cont">
              <div className="home-specialty__no-text-area"></div>
              <div className="home-specialty__text-area">
                <h3 className="home-specialty__h3">LP Smartside</h3>
                <p className="home-specialty__p">
                  LP Smartside composite siding gives you that warm wood look
                  without compromising any longevity. With several painted
                  colors to choose from, this siding may provide the perfect
                  look for you.
                </p>
                <Link
                  to="/siding"
                  className="cta__btn-green home-specialty__btn"
                >
                  Learn More
                </Link>
              </div>
            </div>
            <div className="home-specialties__great-grandchild2-cont">
              <div className="home-specialty__no-text-area"></div>
              <div className="home-specialty__text-area">
                <h3 className="home-specialty__h3">Vestaplank</h3>
                <p className="home-specialty__p">
                  Vestaplank is all the rage right now. It is famous for its
                  longevity and wood-like imitation known as TruCedar. Available
                  in many colors and wood grains, Vestaplank is a great choice
                  for your next project.
                </p>
                <Link
                  to="/siding"
                  className="cta__btn-green home-specialty__btn"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
          <div className="home-specialties__grandchild-cont2">
            <div className="home-specialty__no-text-area"></div>
            <div className="home-specialty__text-area">
              <h3 className="home-specialty__h3">Seamless Gutters</h3>
              <p className="home-specialty__p">
                Our seamless gutters offer a very clean presentation that accent
                every home. They come in a variety of custom components, colors
                and materials. With our large product offering, you will find
                exactly what your home needs to really pop. Enjoy peace of mind
                knowing your gutters have the water management covered.
              </p>
              <Link to="/gutter" className="cta__btn-green home-specialty__btn">
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

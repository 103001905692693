import './App.scss';
// REACT TOOLS
import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// STYLING TOOLS
import { CSSTransition, TransitionGroup } from "react-transition-group";

// STATIC COMPONENTS
import Nav from './ui/static/nav/Nav';
import Footer from './ui/static/footer/Footer';
import FloatingButtonMobile from './ui/static/floatingButtonMobile/FloatingButtonMobile';

// UI COMPONENTS
import Home from './ui/views/home/Home';
import Siding from './ui/views/siding/Siding';
import Gutter from './ui/views/gutter/Gutter';
import OurWork from './ui/views/ourWork/OurWork';
import Insurance from './ui/views/insurance/Insurance';
import Contact from './ui/views/contact/Contact';
import PrivacyPolicy from './ui/views/privacyPolicy/PrivacyPolicy';
import SiteMap from './ui/views/siteMap/SiteMap';
import TermsOfUse from './ui/views/termsOfUse/TermsOfUse';
import NotFound from './ui/views/notFound/NotFound';

// GOOGLE ANALYTICS
import ReactGA from "react-ga";

// HISTORY
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

function App() {
  ReactGA.initialize('UA-168241785-1');
  return (
    <BrowserRouter>
    <Nav />
      <Route
        history={history}
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              timeout={250}
              classNames="fade"
            >
              <Switch location={location}>
                <Route exact={true} path="/" component={Home} />
                <Route path="/siding" component={Siding} />
                <Route path="/gutter" component={Gutter} />
                <Route path="/our-work" component={OurWork} />
                <Route path="/insurance" component={Insurance} />
                <Route path="/contact" component={Contact} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/site-map" component={SiteMap} />
                <Route path="/terms-of-use" component={TermsOfUse} />
                <Route component={NotFound} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
      <FloatingButtonMobile />
      <Footer />
    </BrowserRouter>
  );
}

export default App;

import React, { useState } from "react";

export default function Experts() {

    return(
        <section className="experts__section">
            
            <div className="experts__div-imgBackground">
                <div className="experts__div-cont1">
                    <p className="experts__p-caps">WE ARE PASSIONATE ABOUT HELPING YOU</p>
                    <p className="experts__p-title">Your Siding Repair Experts</p>
                    <p className="experts__p">Having damaged siding can be discouraging. Don’t let it get you down, call us! Paint jobs can only get you so far, each year the paint repair lasts less and less time. Whether your siding is damaged from the extreme Idaho and Wyoming weather, or caused by an accident, we have the tools, materials and experience to replace damaged siding, returning your home to its former glory and keeping it that way for years to come. </p>
                    <a href="/insurance"><p className="experts__p-btn cta__btn-green">Learn More</p></a>
                </div>

                <div className="experts__div-cont2"></div>

                </div>
        </section>

    );

}
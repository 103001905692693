import React, {useState} from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import logo from '../../../images/logo.png';
import navicon from '../../../images/hamicon.png';

export default function Nav() {

    const [toggleNav, setToggleNav] = useState(false);

    let history = useHistory();

    function goHome() {
        history.push('/');
    }

    return(
        <header>
            <nav className="nav">
                <div className="nav__logo-section">
                    <img src={logo} alt="Morgan Exteriors Logo" className="nav__logo" onClick={()=> goHome()}/>
                </div>
                <div className="nav__link-section">
                    <ul className="nav__list">
                        <li className="nav__list-item">
                            <NavLink to="/" className="nav__link">Home</NavLink>
                        </li>
                        <li className="nav__list-item">
                            <NavLink to="/siding" className="nav__link" activeClassName="nav__selected">Siding</NavLink>
                        </li>
                        <li className="nav__list-item">
                            <NavLink to="/gutter" className="nav__link" activeClassName="nav__selected">Gutters</NavLink>
                        </li>
                        <li className="nav__list-item">
                            <NavLink to="/our-work" className="nav__link" activeClassName="nav__selected">Our Work</NavLink>
                        </li>
                        <li className="nav__list-item">
                            <NavLink to="/insurance" className="nav__link" activeClassName="nav__selected">Insurance</NavLink>
                        </li>
                        <li className="nav__list-item">
                            <NavLink to="/contact" className="nav__link" activeClassName="nav__selected">Contact</NavLink>
                        </li>
                        <li className="nav__list-item">
                            <NavLink to="/contact" className="cta__btn-green nav__list-btn">Book a Free Consultation</NavLink>
                        </li>
                    </ul>
                </div>
                <div className="nav__mobile-icon">
                    <img src={navicon} alt="Menu Icon" className="nav__icon" onClick={()=> setToggleNav(!toggleNav)}/>
                </div>
            </nav>
            <div className={toggleNav ? "nav__dropdown-mobile" : "nav__dropdown-closed"}>
                <ul className="nav__list-mobile">
                    <li className="nav__list-item-mobile">
                        <NavLink to="/" className="nav__link-mobile" onClick={()=> setToggleNav(!toggleNav)}>Home</NavLink>
                    </li>
                    <li className="nav__list-item-mobile">
                        <NavLink to="/siding" className="nav__link-mobile" activeClassName="nav__selected" onClick={()=> setToggleNav(!toggleNav)}>Siding</NavLink>
                    </li>
                    <li className="nav__list-item-mobile">
                        <NavLink to="/gutter" className="nav__link-mobile" activeClassName="nav__selected" onClick={()=> setToggleNav(!toggleNav)}>Gutters</NavLink>
                    </li>
                    <li className="nav__list-item-mobile">
                        <NavLink to="/our-work" className="nav__link-mobile" activeClassName="nav__selected" onClick={()=> setToggleNav(!toggleNav)}>Our Work</NavLink>
                    </li>
                    <li className="nav__list-item-mobile">
                        <NavLink to="/insurance" className="nav__link-mobile" activeClassName="nav__selected" onClick={()=> setToggleNav(!toggleNav)}>Insurance</NavLink>
                    </li>
                    <li className="nav__list-item-mobile">
                        <NavLink to="/contact" className="nav__link-mobile" activeClassName="nav__selected" onClick={()=> setToggleNav(!toggleNav)}>Contact</NavLink>
                    </li>
                    <li className="nav__list-item-mobile">
                        <NavLink to="/contact" className="nav__list-btn-mobile cta__btn-green" onClick={()=> setToggleNav(!toggleNav)}>Book a Free Consultation</NavLink>
                    </li>
                </ul>
            </div>
        </header>
    );
}
import React from "react";
import one from "../../../../images/insurance/vinyl1.png";
import two from "../../../../images/insurance/vinyl2.png";
import three from "../../../../images/insurance/vinyl3.png";
import four from "../../../../images/insurance/vinyl4.png";

export default function VinylRepair() {
  return (
    <section className="vinRepair__cont">
      <div className="vinRepair__div-color">
        <div className="vinRepair__cont-center">
          <div className="vinRepair__div-grid">
            <div className="vinRepair__div-text">
              <h2>Vinyl Damage Repair</h2>
                  <li>Free Inspections</li>
                  <li>Fast & Reliable Repairs</li>
                  <li>5-Star Google Rating</li>
                  <li>Precision Matching</li>
                  <li>Experienced with Insurance Claims</li>
                  <li>No Deductible Required</li>
                  <li>Licensed, Bonded & Insured</li>
                  <li>25 Years as an Idaho Falls Contractor</li>
              <div>
                <h3>Approved By Your Insurance</h3>
              </div>
            </div>
            <div className="vinRepair__div-photos">
              <div>
                <img src={one} alt="" />
              </div>
              <div>
                <img src={two} alt="" />
              </div>
              <div>
                <img src={three} alt="" />
              </div>
              <div>
                <img className="vinRepair__img-disapear" src={four} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import React, { useState } from "react";
import { Link } from 'react-router-dom';


export default function GuttergutterExperts() {
  return (
    <section className="gutterExperts__section">
      <div className="gutterExperts__div-imgBackground">
        <div className="gutterExperts__div-cont2"></div>
        <div className="gutterExperts__div-cont1">
          <p className="gutterExperts__p-caps">
            KEEPING YOUR GUTTERS READY FOR ANYTHING
          </p>
          <p className="gutterExperts__p-title">Your Gutter Repair Experts</p>
          <p className="gutterExperts__p">
            From gutters full of plants to damage from a heavy snow fall, we
            have seen it all! When it comes to repairs and cleaning, our
            professionals can get you back to normal in no time. We have the
            ability to match colors, custom form gutter to your home while
            providing the classy seamless look. Call us today to get your home
            scheduled.
          </p>
          <Link to="/insurance"><p className="gutterExperts__p-btn cta__btn-green">Learn More</p></Link>
        </div>
      </div>
    </section>
  );
}

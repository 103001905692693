import React from "react";
import { Link } from 'react-router-dom';

export default function Hangers() {
  return (
    <section className="hangers__section">
      <div className="hangers__div-colorBox"></div>
      <h2 className="hangers__h2">The Strongest Hidden Hanger Ever Made</h2>
      <div className="hangers__div-cont">
          <div className="hangers__div-text">
      <p className="hangers__p">
        Ever seen a metal strap hanger flapping on your neighbors gutter? Or
        perhaps you have seen what looks like a nail placed every few feet? With our specialized hidden hangers, your gutters will never have that problem and will always look clean and crisp. Although the hangers are hidden, it does not mean we sacrificed anything in strength or longevity. Our hangers last long and are the strongest in the industry; all while staying out of sight.
      </p>
      <a href="https://www.raytecllc.com/division/roofing-products/hidden-gutter-hangers-2"><p className="hangers__p-btn cta__btn-green">See More Options</p></a>
      </div>
      <div className="hangers__div-img"></div>
      </div>
    </section>
  );
}

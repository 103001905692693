import React, {useEffect} from 'react';
import { Helmet } from "react-helmet";
import { usePageView } from "../../../services/customHooks";
import HomeBanner from '../home/children/HomeBanner';
import HomeVision from '../home/children/HomeVision';
import HomeSpecialties from '../home/children/HomeSpecialties';
import HomeBuy from '../home/children/HomeBuy';
import HomeElite from '../home/children/HomeElite';
import HomeCustomers from '../home/children/HomeCustomers';
import HomeDiscounts from '../home/children/HomeDiscounts';
import HomeComRes from '../home/children/HomeComRes';

export default function Home(props) {
    usePageView(props.location.pathname);

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    return(
        <main className="home">
            <Helmet>
                <title>Morgan Exteriors - Idaho and Western Wyoming's #1 Choice for Seamless Exteriors</title>
                <meta
                    name="description"
                    content="Morgan Exteriors specializies in seamless steel siding, seamless gutter, LP Smartside, Vesta Plank, Vinyl, Soffit and Fascia, TruCedar Shakes and much more. Call us today at (208) 643-3612"
                />
            </Helmet>
            <HomeBanner />
            <HomeVision />
            <HomeSpecialties />
            <HomeBuy />
            <HomeElite />
            <HomeCustomers />
            <HomeDiscounts />
            <HomeComRes />
        </main>
    );
}
import React from "react";

export default function insuranceCTACTA() {
  return (
    <section className="insuranceCTA__section-container">
      <div className="insuranceCTA__div-container">
        <div className="insuranceCTA__div-leftAligned">
          <p className="insuranceCTA__p-bigText">We will work with <b><i>YOUR</i></b> insurance</p>
        </div>
        <div className="insuranceCTA__div-rightAligned">
          <a href="tel:2086433612">
            <p className="insuranceCTA__btn cta__btn-green">
              Call for a Free Quote Today
            </p>
          </a>
        </div>
      </div>
    </section>
  );
}

import React from "react";


export default function GutterBanner() {
  return (
    <section className="gutterBanner__section-container">
      <div className="gutterBanner__div-container">
        <h1 className="gutterBanner__h1">Be Prepared for Fall and Winter</h1>
        <h2 className="gutterBanner__h2">Gutter cleaning, leaf guard and repair services</h2>
      </div>
    </section>
  );
}
import React from "react";


export default function HomeBanner() {
  return (
    <section className="homeBanner__section-container">
      <div className="homeBanner__div-container">
        <h1 className="homeBanner__h1">Your Local Siding Experts</h1>
      </div>
    </section>
  );
}
import React, { useState } from "react";
import { Link } from 'react-router-dom';

export default function ColorPallete() {
  return (
    <section className="colorPallette__section">
      <h2 className="colorPallette__h2">
        Gutter Pieces are Available in Many Colors
      </h2>
      <p className="colorPallette__p">Check Out Some Options</p>
      <div className="colorPallette__div-colorPalletteCont">
        <div className="colorPallette__div-cont color7">
          <p className="colorPallette__p-colorName">30 White</p>
        </div>
        <div className="colorPallette__div-cont color8">
          <p className="colorPallette__p-colorName">19 Black</p>
        </div>
        <div className="colorPallette__div-cont color9">
          <p className="colorPallette__p-colorName">80 Charcoal</p>
        </div>
        <div className="colorPallette__div-cont color10">
          <p className="colorPallette__p-colorName">15 Forest Green</p>
        </div>
        <div className="colorPallette__div-cont color11">
          <p className="colorPallette__p-colorName">59 Musket</p>
        </div>
        <div className="colorPallette__div-cont color12">
          <p className="colorPallette__p-colorName">A7 Wicker</p>
        </div>
      </div>
      <p className="colorPallette__p">
        Here are just some popular options. Feel free to give us a call to see
        if we have the color you are looking for.
      </p>
      <a href="tel:2086433612"> <p className="colorPallette__p-btn cta__btn-green">Call (208) 643-3612</p></a>
      
    </section>
  );
}

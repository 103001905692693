import React from 'react';
import image from '../../../../images/home/guarantee.png';

export default function HomeCustomers() {
    return(
        <section className="home-customers__cont">
            <div className="home-customers__text-cont">
                <h2 className="home-customers__h2">Satisfying Customers Since 1996</h2>
                <p className="home-customers__p">Customer satisfaction is our top priority. Our hard working professional team makes sure our customers are satisfied with our work. We always strive for excellence and quality, and our customers know it. We have a five star rating on Google Reviews, read to find out why.</p>
                <a href="https://www.google.com/search?q=morgan+exteriors+idaho&rlz=1C1CHBD_enUS882US882&oq=morgan+exteriors+idaho&aqs=chrome.0.69i59j0i22i30i457j69i59j69i60j69i61l2.4410j0j15&sourceid=chrome&ie=UTF-8&safe=active&ssui=on#wptab=s:H4sIAAAAAAAAAONgVuLVT9c3NEwvzjMzT0o3ecTozS3w8sc9YSmnSWtOXmO04eIKzsgvd80rySypFNLjYoOyVLgEpVB1ajBI8XOhCvHsYuL3yU9OzAnIzwxKLctMLS9exKqSm1-UnpinkFpRklqUmV9UrJCZkpiRr5CWmJNTrFAEUQYAL_4DxpgAAAA" className="cta__btn-green">See Reviews</a>
            </div>
            <div className="home-customers__image-cont">
                <img src={image} alt="100% Satisfaction Guaranteed" className="home-customers__image"/>
            </div>
        </section>
    );
}
import React, { useState } from "react";

export default function SidingProducts() {

  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);
  const [toggle4, setToggle4] = useState(false);
  const [toggle5, setToggle5] = useState(false);

  return (
    <section className="sidingProducts__section-container">
      <h2 className="sidingProducts__h2">See Our Vast Product Offerings</h2>

      <div className="sidingProducts__div-desktop">
        <div className="sidingProducts__div-grid">
          <div className="sidingProducts__div-boxOne sidingProducts__div-box">
            <h3  >Seamless Steel</h3>
            <a href="#seamless-steel">
            <p className="sidingProducts__p-btn cta__btn-green">
                    Learn More
                </p></a>
          </div>
          <div className="sidingProducts__div-boxTwo sidingProducts__div-box">
            <h3   >LP Smartside</h3>
            <a href="#lp-smartside">
            <p className="sidingProducts__p-btn cta__btn-green">
                    Learn More
                </p></a>
          </div>
          <div className="sidingProducts__div-boxThree sidingProducts__div-box">
            <h3  >Vesta Plank</h3>
            <a href="#vesta-plank">
            <p className="sidingProducts__p-btn cta__btn-green">
                    Learn More
                </p></a>
          </div>
          <div className="sidingProducts__div-boxFour sidingProducts__div-box">
            <h3  >TruCedar Shakes</h3>
            <a href="#trucedar-shakes">
            <p className="sidingProducts__p-btn cta__btn-green">
                    Learn More
                </p></a>
          </div>
          <div className="sidingProducts__div-boxFive sidingProducts__div-box">
            <h3  >Soffit and Fascia</h3>
            <a href="#soffit-and-fascia">
            <p className="sidingProducts__p-btn cta__btn-green">
                    Learn More
                </p></a>

          </div>
       
        </div>
      </div>

      <div className="sidingProducts__div-mobile">
        <div className="sidingProducts__div-grid">
          <div
            onClick={() => setToggle(!toggle)}
            className={
              toggle
                ? "sidingProducts__div-box_open1" + " sidingProducts__div-boxOne"
                : "sidingProducts__div-boxMobile1" + " sidingProducts__div-boxOne"
            }
          >
            <h3  >Seamless Steel</h3>
            <a href="#seamless-steel"><p className={toggle
                ? "sidingProducts__p-btnOpen": "sidingProducts__p-btn" }>
                    Learn More
                </p></a>
          </div>
          <div
            onClick={() => setToggle2(!toggle2)}
            className={
              toggle2
                ? "sidingProducts__div-box_open2" + " sidingProducts__div-boxTwo"
                : "sidingProducts__div-boxMobile2" + " sidingProducts__div-boxTwo"
            }
          >
            <h3  >Lp Smartside</h3>
            <a href="#lp-smartside"><p className={toggle2
                ? "sidingProducts__p-btnOpen": "sidingProducts__p-btn" }>
                    Learn More
                </p></a>
          </div>
          <div
            onClick={() => setToggle3(!toggle3)}
            className={
              toggle3
                ? "sidingProducts__div-box_open3" + " sidingProducts__div-boxThree"
                : "sidingProducts__div-boxMobile3" + " sidingProducts__div-boxThree"
            }
          >
            <h3  >Vesta Plank</h3>
            <a href="#vesta-plank"><p className={toggle3
                ? "sidingProducts__p-btnOpen": "sidingProducts__p-btn" }>
                    Learn More
                </p></a>
          </div>
          <div
            onClick={() => setToggle4(!toggle4)}
            className={
              toggle4
                ? "sidingProducts__div-box_open4" + " sidingProducts__div-boxFour"
                : "sidingProducts__div-boxMobile4" + " sidingProducts__div-boxFour"
            }
          >
            <h3  >TruCedar Shakes</h3>
            <a href="#trucedar-shakes"><p className={toggle4
                ? "sidingProducts__p-btnOpen": "sidingProducts__p-btn" }>
                    Learn More
                </p></a>
          </div>
          <div
            onClick={() => setToggle5(!toggle5)}
            className={
              toggle5
                ? "sidingProducts__div-box_open5" + " sidingProducts__div-boxFive"
                : "sidingProducts__div-boxMobile5" + " sidingProducts__div-boxFive"
            }
          >
            <h3  >Soffit and Fascia</h3>
            <a href="#soffit-and-fascia"><p className={toggle5
                ? "sidingProducts__p-btnOpen" : "sidingProducts__p-btn"}>
                    Learn More
                </p></a>
          </div>
         
        </div>
      </div>
    </section>
  );
}

import React from "react";

export default function SixInRound() {
  return (
    <section className="sixInRound__section-container" id="6in-round">
      <div className="sixInRound__div-colorBox"></div>
      <div className="sixInRound__div-img"></div>
      <div className="sixInRound__div-textCont">
          <h2 className="sixInRound__h2">6" Half Round Gutter</h2>
          <p className="sixInRound__p">
          Half Round Gutter Systems are making a comeback. They offer an exquisite and luxurious look to your home and are sure to make your home the envy of everyone on the block. Typically paired with cylindrical downtubes, we offer you complete customization with a seamless presentation. They come with a baked on enamel finish and many color options to get the perfect look for your home.
          </p>
      </div>
    </section>
  );
}

import React, { useState } from "react";

import steel from '../../../../images/siding/seamlessSteel1.jpg'

export default function Steel() {

    return(
        <section className="steel__section" id="seamless-steel">
            <h2 className="steel__h2">Seamless Steel</h2>
            
            <div className="steel__div-colorBlock">

            <div className="steel__div-Cont">

                <div className="steel__div-textCont">
                    <p className="steel__p">
                    Our siding is a step up from others because it's made from durable steel and is seamless. Other siding products must be constantly overlapped or shoved together every 12ft to reach the length of your home, creating a pattern of ugly splices and joints. The average home has over 130 splices. With our steel siding, we eliminate those unsightly splices and joints, and give you a smooth, clean-looking exterior with lengths that are perfectly formed to fit the dimensions of your home.</p>

                    <p>To accomplish this, we use a state of the art machine, known as a Roll Former to create the custom pieces for your home. Since it is mounted inside a trailer, we bring the machine straight to you and manufacture each piece on site. Our machine allows us to make 16 different profiles and apply a  unique woodgrain embossed textured for a large variety of options.</p>

                    <p>There are many color and hue options, to learn more give us a call at 208-643-3612 or click the button below.</p>
                    
                </div>
                <div className="steel__img"/></div>
                </div>
        </section>

    );

}
import React from "react";


export default function ContactBanner() {
  return (
    <section className="contactBanner__section-container">
      <div className="contactBanner__div-container">
        <h1 className="contactBanner__h1">Highly Trained Professionals</h1>
      </div>
    </section>
  );
}
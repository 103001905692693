import React from 'react';
import { Link, useHistory } from "react-router-dom";
import video from '../../../../video/bannervideo.mp4';
import option1 from '../../../../images/home/seamlessSteel.jpg';

export default function HomeBanner() {

    let history = useHistory();

    function toContact() {
        history.push('/contact');
    }
    
    const styles = {
        background:
          "linear-gradient(45deg, rgba(4, 4, 4, 0.3), rgba(4, 4, 4, 0.3)), url(" +
          option1 +
          ")",
        backgroundPosition: "left top",
        backgroundSize: "cover",
      };

    return(
        <section className="home-banner" style={styles}>
            <video
                className="home-banner__video"
                playsInline
                muted
                autoPlay
                loop
                src={video}
            />
        <div className="home-banner__video-gradient" />
        <div className="home-banner__h1-cont">
            <h1 className="home-banner__h1">Idaho & Western Wyoming's<br/> #1 Choice for Seamless Exteriors</h1>
            <button className="cta__btn-green home-banner__CTA-btn" onClick={()=> toContact()}>
                Book a Free Consultation
            </button>
        </div>
    </section>
    );
}
import React from 'react';
import ReactPlayer from "react-player";
import pic from '../../../../images/home/elitework.jpg';

export default function HomeElite() {
    return(
        <section className="homeelite__cont">
            <div className="homeelite__video-cont">
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=Ilc-fPfmXzo"
                    light={pic}
                    className="react-player"
                    width="100%"
                />
            </div>
            <div className="homeelite__text-cont">
                <h2 className="homeelite__h2">Elite Craftsmanship</h2>
                <p className="homeelite__p">Our certified professionals have over 20 years of experience in the exterior industry. With professional processes and high quality materials, you can expect the best workmanship in a short period of time for a high value service. Your home or business will transform to look its absolute best.</p>
            </div>
        </section>
    );
}
import React, { useState } from "react";

import image1 from '../../../../images/siding/vestaPlank1.jpg';
import image2 from '../../../../images/siding/vestaPlank2.jpg';

export default function VestaPlank() {

    return(
        <section className="vestaPlank__section" id="vesta-plank">
            <h2 className="vestaPlank__h2">Vesta Plank</h2>
            
           

            <div className="vestaPlank__div-Cont">

                <div className="vestaPlank__div-colorBlock"></div>


                    <p className="vestaPlank__p1">Vesta Plank is one of the most popular products from Quality Edge. It provides a high class look, without the maintenance and durability to compete with the market’s top performers. <br/>
                    <br/>

                   Vesta’s steel core is unlike any other and designed to defend against whatever nature throws at it. An anti-corrosive zinc barrier provides unyielding protection born to last generations.</p>

                    <div className="vestaPlank__img vestaPlank__img1"></div>

                    <div className="vestaPlank__img vestaPlank__img2"></div>

                    <div className="vestaPlank__p2">
                    <p>One of the most environmentally durable sidings on the market, engineered wood combines the aesthetics of real wood with engineered wood strand technology for superior durability. Withstanding the passage of time, it’s a great choice for versatile applications.</p>

                    <a href="https://www.qualityedge.com/vesta/?gclid=Cj0KCQiA2uH-BRCCARIsAEeef3m4l6rxK5gqXXAcKuwERI33lqGf1sRDvFsPwUXNmw3XXv3CM_SBARgaAijzEALw_wcB
"><p className="vestaPlank__p-btn cta__btn-green">Learn More</p></a>
                    </div>

                    </div>
               
        </section>

    );

}
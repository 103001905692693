import React from 'react';
import res from '../../../../images/home/residential.jpg';
import com from '../../../../images/home/commercial.jpg';


export default function HomeComRes() {
    return(
        <section className="home-comres__cont">
            <div className="home-comres__res">
                <h2 className="home-comres__h2">Stunning Residences</h2>
                <p className="home-comres__p">Whether your home is custom or cookie cutter, our professional exterior work can turn your home into the spectacle on the block. By choosing from our many product and material offerings, we can find the perfect look for your home that turns your dreams into reality. Our expert contractors are always available to sit down with you to plan out the look of your new home exterior. Call us today to set an appointment or to speak with one of our knowledgeable professionals.</p>
            </div>
            <div className="home-comres__com">
                <h2 className="home-comres__h2">Eye Catching Commercial</h2>
                <p className="home-comres__p">The exterior of your business is often the first impression you will make on your customers. An attractive exterior powerfully communicates trust and professionalism. What impression are you looking to make? Our expert contractors have been in the commercial industry for over 20 years, and are the leaders in customization and client satisfaction. Whatever your vision, we can make it a reality. Call us today to set an appointment or to speak with one of our knowledgeable professionals.</p>
            </div>
        </section>
    );
}
import React from "react";

export default function SixInK() {
  return (
    <section className="sixInK__section-container" id="6in-k-style">
        <div div className="sixInK__div-Text">
          <h2 className="sixInK__h2">6" Style K Gutter</h2>
          <p className="sixInK__p">
            6” Style K Gutter gives you the most effective and reliable drainage
            gutters can offer. Have a big roof? Then there is no question you
            will want our 6” gutter for the job. It specializes in handling the
            weight and volume of water coming from a large surface area and
            distributing it away from your home.
          </p>

          <p>
            Our seamless gutter system provides that classic Crown Mold look
            without any unsightly seams. Our gutter is formed on site for a
            custom fit to your home. They come with a baked on enamel finish and
            many color options to get the perfect look for your home.
          </p>
        </div>
        <div className="sixInK__div-imgCont">
          </div>
    </section>
  );
}

import React, {useEffect} from 'react';
import { Helmet } from "react-helmet";
import { usePageView } from "../../../services/customHooks";
import ContactBanner from "./children/ContactBanner";
import WhoWeAre from "./children/WhoWeAre";
import ContactUs from "./children/contactUs";


export default function Contact(props) {
    usePageView(props.location.pathname);
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
    return(
        <main className="contact">
            <ContactBanner/>
             <div className="spacing"></div>
             <WhoWeAre/>
             <div className="spacing"></div>
             <ContactUs/>
             <div className="spacing"></div>
         </main>
    );
}
import React, { useState, useEffect } from "react";
import photo1 from "../../../../images/work/ourWork1.jpg";
import photo2 from "../../../../images/work/ourWork2.jpg";
import photo3 from "../../../../images/work/ourWork3.jpg";
import photo4 from "../../../../images/work/ourWork4.jpg";
import photo5 from "../../../../images/work/ourWork5.jpg";
import photo6 from "../../../../images/work/ourWork6.jpg";
import photo7 from "../../../../images/work/ourWork7.jpg";
import photo8 from "../../../../images/work/ourWork8.jpg";
import photo9 from "../../../../images/work/ourWork9.jpg";
import photo10 from "../../../../images/work/ourWork10.jpg";
import photo11 from "../../../../images/work/ourWork11.jpg";
import photo12 from "../../../../images/work/ourWork12.jpg";
import { useInterval } from "react-use";

export default function HomeVision() {
  const homeArray = [
    { alt: "Beautiful Home", src: photo1 },
    { alt: "Beautiful Home", src: photo2 },
    { alt: "Beautiful Home", src: photo3 },
    { alt: "Beautiful Home", src: photo4 },
    { alt: "Beautiful Home", src: photo5 },
    { alt: "Beautiful Home", src: photo6 },
    { alt: "Beautiful Home", src: photo7 },
    { alt: "Beautiful Home", src: photo8 },
    { alt: "Beautiful Home", src: photo9 },
    // { alt: "Beautiful Home", src: photo10 },
    { alt: "Beautiful Home", src: photo11 },
    { alt: "Beautiful Home", src: photo12 },
  ];

  const [index, setIndex] = useState(0);

  useInterval(() => {
    if (index === homeArray.length - 1) {
      setIndex(0);
    } else {
      setIndex((oldIndex) => oldIndex + 1);
    }
  }, 4000);

  return (
    <section className="homevision__cont">
      <div className="homevision__text-cont">
        <h2 className="homevision__h2">Bring Your Vision to Life</h2>
        <p className="homevision__p">
          We are passionate about understanding your vision and bringing it to
          life. Our product offering is so vast that we are absolutely certain
          that we can provide you with the look and feel you desire.
        </p>
        <ul className="homevision__list">
          <li className="homevision__list-item">
            50+ products and 1000's of combinations
          </li>
          <li className="homevision__list-item">
            100+ colors and colored textures
          </li>
          <li className="homevision__list-item">15+ siding profiles</li>
          <li className="homevision__list-item">And much more!</li>
        </ul>
        <a href="tel:208-643-3612" className="homevision__cta cta__btn-green">
          Call and ask about our product offerings!
        </a>
      </div>
      <div className="homevision__picture-cont">
        <img
          src={homeArray[index].src}
          alt={homeArray[index].alt}
          className="homevision__img"
        />
      </div>
    </section>
  );
}

import React, {useEffect} from 'react';
import { Helmet } from "react-helmet";
import { usePageView } from "../../../services/customHooks";
import GutterBanner from "./children/GutterBanner";
import GutterProducts from "./children/GutterProducts";
import Materials from "./children/Materials";
import FiveInK from "./children/FiveInK";
import SixInK from "./children/SixInK";
import SixInRound from "./children/SixInRound";
import Hangers from "./children/Hangers";
import ColorPallette from "./children/ColorPallette";
import LeafGuard from "./children/LeafGuard";
import CloggedCTA from "./children/CloggedCTA";
import GutterExpert from "./children/GutterExpert";


export default function Gutter(props) {
    
    usePageView(props.location.pathname);
    
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    return(
        <main className="gutter">
            <GutterBanner/>
            <div className="spacing"></div>
            <GutterProducts/>
            <div className="spacing"></div>
            <Materials/>
            <div className="spacing"></div>
            <FiveInK/>
            <div className="spacing"></div>
            <SixInK/>
            <div className="spacing"></div>
            <SixInRound/>
            <div className="spacing"></div>
            <Hangers/>
            <div className="spacing"></div>
            <ColorPallette/>
            <div className="spacing"></div>
            <LeafGuard/>
            <div className="spacing"></div>
            <CloggedCTA/>
            <div className="spacing"></div>
            <GutterExpert/>
            <div className="spacing"></div>
        </main>
    );
}
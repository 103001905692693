import React, { useState } from "react";

export default function GutterProducts() {

  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);

  return (
    <section className="gutterProducts__section-container">
      <h2 className="gutterProducts__h2">See Our Vast Product Offerings</h2>

      <div className="gutterProducts__div-desktop">
        <div className="gutterProducts__div-grid">
          <div className="gutterProducts__div-boxOne gutterProducts__div-box">
            <h3>5" Style K Gutter</h3>
            <a href="#5in-k-style">
            <p className="gutterProducts__p-btn cta__btn-green">
                    Learn More
                </p></a>
          </div>
          <div className="gutterProducts__div-boxTwo gutterProducts__div-box">
            <h3>6" Style K Gutter</h3>
            <a href="#6in-k-style">
            <p className="gutterProducts__p-btn cta__btn-green">
                    Learn More
                </p></a>
          </div>
          <div className="gutterProducts__div-boxThree gutterProducts__div-box">
            <h3>6" Half Round Gutter</h3>
            <a href="#6in-round">
            <p className="gutterProducts__p-btn cta__btn-green">
                    Learn More
                </p></a>
          </div>
       
        </div>
      </div>

      <div className="gutterProducts__div-mobile">
        <div className="gutterProducts__div-grid">
          <div
            onClick={() => setToggle(!toggle)}
            className={
              toggle
                ? "gutterProducts__div-box_open1" + " gutterProducts__div-boxOne"
                : "gutterProducts__div-boxMobile1" + " gutterProducts__div-boxOne"
            }
          >
            <h3>5" Style K Gutter</h3>
            <a href="#5in-k-style"><p className={toggle
                ? "gutterProducts__p-btnOpen": "gutterProducts__p-btn" }>
                    Learn More
                </p></a>
          </div>
          <div
            onClick={() => setToggle2(!toggle2)}
            className={
              toggle2
                ? "gutterProducts__div-box_open2" + " gutterProducts__div-boxTwo"
                : "gutterProducts__div-boxMobile2" + " gutterProducts__div-boxTwo"
            }
          >
            <h3>6" Style K Gutter</h3>
            <a href="#6in-k-style"><p className={toggle2
                ? "gutterProducts__p-btnOpen": "gutterProducts__p-btn" }>
                    Learn More
                </p></a>
          </div>
          <div
            onClick={() => setToggle3(!toggle3)}
            className={
              toggle3
                ? "gutterProducts__div-box_open3" + " gutterProducts__div-boxThree"
                : "gutterProducts__div-boxMobile3" + " gutterProducts__div-boxThree"
            }
          >
            <h3>6" Half Round Gutter</h3>
            <a href="#6in-round"><p className={toggle3
                ? "gutterProducts__p-btnOpen": "gutterProducts__p-btn" }>
                    Learn More
                </p></a>
          </div>
          
         
        </div>
      </div>
    </section>
  );
}

import React, { useState } from "react";
export default function Colors() {

    return(
        <section className="colors__section">
            <h2 className="colors__h2">Siding Available in Many Colors</h2>
            <p className="colors__p">Check Out Some of Our Options</p>
            <div className="colors__div-colorsCont">
                <div className="colors__div-cont color1">
                    <p className="colors__p-colorName">106 Timber Ash</p>
                </div>
                <div className="colors__div-cont color2">
                <p className="colors__p-colorName">109 Cedar</p>
                </div>
                <div className="colors__div-cont color3">
                <p className="colors__p-colorName">417 Birch</p>
                </div>
                <div className="colors__div-cont color4">
                <p className="colors__p-colorName">414 Ironstone</p>
                </div>
                <div className="colors__div-cont color5">
                <p className="colors__p-colorName">424 Slate Grey</p>
                </div>
                <div className="colors__div-cont color6">
                <p className="colors__p-colorName">819 Harringbone</p>
                </div>
            </div>
            <p className="colors__p">Here are just some popular options. Feel free to give us a call to see if we have the color you are looking for.</p>
            <a href="../../../../images/siding/qualityEdgeGuide.pdf" download="QualityEdgeGuide" ><p className="colors__p-btn cta__btn-green">Learn More</p></a>
        </section>

    );

}
import React from 'react';
import damagedGutter from '../../../../images/insurance/gutterDamage.jpg';
import damagedVinyl from '../../../../images/insurance/vinyl3.png';
import damagedSiding from '../../../../images/insurance/sidingDamage.jpg';
import houseDamage from '../../../../images/insurance/houseDamge.jpg';
import damagedRoof from '../../../../images/insurance/roofDamage.jpg';

export default function InsuranceRepair() {
  return (
    <section className="insurance-repair__cont">
      <h2 className="ir__h2">Repair Professionals</h2>
      <div className="insurance-repair__mobile-cont">
        <div className="ir__mobile-parent1">
          <h3 className="ir__mobile-h3">Gutters</h3>
        </div>
        <div className="ir__mobile-parent2">
          <div className="ir__mobile-child1">
            <div className="ir__mobile-grandchild1">
              <h3 className="ir__mobile-h3">Vinyl Fencing</h3>
            </div>
            <div className="ir__mobile-grandchild2">
              <h3 className="ir__mobile-h3">Siding</h3>
            </div>
          </div>
          <div className="ir__mobile-child2">
            <p className="ir__mobile-p">
              Our team specializes in in repairing your home exteriors that have
              been damaged by hail or storms.
            </p>
          </div>
        </div>
        <div className="ir__mobile-parent3">
          <h3 className="ir__mobile-h3">Roofing</h3>
        </div>
      </div>
      <div className="insurance-repair__desktop-cont">
        <div className="ir__desktop-parent1">
          <h3 className="ir__desktop-h3">Gutters</h3>
        </div>
        <div className="ir__desktop-parent2">
          <div className="ir__desktop-child1">
            <div className="ir__desktop-grandchild1">
              <h3 className="ir__desktop-h3">Vinyl Fencing</h3>
            </div>
            <div className="ir__desktop-grandchild2">
              <h3 className="ir__desktop-h3">Siding</h3>
            </div>
          </div>
          <div className="ir__desktop-child2">
            <p className="ir__desktop-p">
              Our team specializes in in repairing your home exteriors that have
              been damaged by hail or storms.
            </p>
          </div>
        </div>
        <div className="ir__desktop-parent3">
          <h3 className="ir__desktop-h3">Roofing</h3>
        </div>
      </div>
    </section>
  );
}

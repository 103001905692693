import React from "react";
import glass from "../../../../images/insurance/magGlass1.png";
import mark from "../../../../images/insurance/checkMark1.png";
import dollar from "../../../../images/insurance/money1.png";
export default function InsuranceHighlights () {

return(
<section className="highlights__section-container">
        <div className="highlights__div-one">
          <img src={glass} alt="magnifying glass"/>
          <h3>Free Inspections</h3>
          <p>Get a free professional opinion</p>
        </div>
        <div className="highlights__div-two">
          <img src={mark} alt="check mark"/>
          <h3>Insurance Approved</h3>
          <p>We work with your insurance company</p>
        </div>
        <div className="highlights__div-three">
          <img src={dollar} alt="dollar sign"/>
          <h3>No Deductible Required</h3>
          <p>Get your recoverable depreciation before its gone</p>
        </div>
      </section>
);
}
import React from "react";

export default function FiveInK() {
  return (
    <section className="fiveInK__section-container" id="5in-k-style">
      <div className="fiveInK__div-backImg"></div>
      <div className="fiveInK__div-colorBox"></div>
      <div className="fiveInK__div-Text">
        <h2 className="fiveInK__h2">5" Style K Gutter</h2>
        <p className="fiveInK__p">
          Style K Gutter is the most popular gutter profile in America. Our
          seamless gutter system provides that classic Crown Mold look without
          any unsightly seams. Our gutter is formed on site for a custom fit to
          your home. They come with a baked on enamel finish and many color
          options to get the perfect look for your home.
        </p>
      </div>
      <div className="fiveInK__div-img"></div>
    </section>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';

export default function HomeDiscounts() {
    return(
        <section className="homediscounts__cont">
            <div className="homediscounts__text-cont">
                <p className="homediscounts__tagline">We offer Military and Senior Discounts.</p>
            </div>
            <div className="homediscounts__action-cont">
                <Link to="/contact" className="cta__btn-green">Request a Free Quote</Link>
            </div>
        </section>
    );
}
import React, { useState } from "react";

export default function LpSmartside() {

    return(
        <section className="lpSmartside__section" id="lp-smartside">
            <h2 className="lpSmartside__h2">LP Smartside Composite</h2>
            
           

            <div className="lpSmartside__div-Cont">

                <div className="lpSmartside__div-colorBlock"></div>


                    <p className="lpSmartside__p1">While aesthetics are near the top of the list when choosing your siding, durability is usually the next concern. With LP Smartside, you get the best of both worlds! LP Smartside is engineered to be extremely impact resistant as well as waterproof, while providing that famous painted wood look. </p>

                    <div className="lpSmartside__img lpSmartside__img1"></div>

                    <div className="lpSmartside__img lpSmartside__img2"></div>

                    <div className="lpSmartside__p2">
                    <p>One of the most environmentally durable sidings on the market, engineered wood combines the aesthetics of real wood with engineered wood strand technology for superior durability. Withstanding the passage of time, it’s a great choice for versatile applications.</p>

                    <a href="https://lpcorp.com/products/exterior/siding-trim"><p className="lpSmartside__p-btn cta__btn-green">Learn More</p></a>
                    </div>

                    </div>
               
        </section>

    );

}
import React from "react";

export default function WhoWeAre() {
  return (
    <section className="whoWeAre__section-container">
      <div className="whoWeAre__div-colorBox"></div>
      <div className="whoWeAre__div-img"></div>
      <div className="whoWeAre__div-textCont">
          <h2 className="whoWeAre__h2">Who We Are</h2>
          <p className="whoWeAre__p">
          Morgan Exteriors was established in 1996 and has taken the exterior industry by storm ever since. With over 20 years of experience, Morgan Exteriors has a reputation for high quality work, excellent customer service, and a specialty in high end homes and commercial buildings. As a privately owned company, Morgan Exteriors provides a custom touch in both experience and value unlike any other company. When only the highest quality, exceptional value, and a personalized experience will do, Morgan Exteriors is up for the challenge.
          </p>
      </div>
    </section>
  );
}
